.my-projects {
  background-image: url("../fute-screens/my-projects.png");
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.my-projects-backdrop {
  background-image: url("../fute-screens/my-projects-backdrop.png");
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.link-project {
  background-image: url("../fute-screens/link-project.png");
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.link-project-backdrop {
  background-image: url("../fute-screens/link-project-backdrop.png");
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.all-escalation {
  background-image: url("../fute-screens/all-escalation.png");
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.all-escalation-backdrop {
  background-image: url("../fute-screens/all-escalation-backdrop.png");
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.internal-issue {
  background-image: url("../fute-screens/all-internal-issues.jpg");
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.internal-issue-backdrop {
  background-image: url("../fute-screens/all-internal-issues-backdrop.jpg");
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.all-risks {
  background-image: url("../fute-screens/all-risks.jpg");
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.all-risks-backdrop {
  background-image: url("../fute-screens/all-risks-backdrop.jpg");
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.dependency {
  background-image: url("../fute-screens/dependency.jpg");
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.dependency-backdrop {
  background-image: url("../fute-screens/dependency-backdrop.jpg");
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.assumptions {
  background-image: url("../fute-screens/assumption.jpg");
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.assumptions-backdrop {
  background-image: url("../fute-screens/assumption-backdrop.jpg");
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.actions {
  background-image: url("../fute-screens/all-actions.jpg");
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.actions-backdrop {
  background-image: url("../fute-screens/all-actions-backdrop.jpg");
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
