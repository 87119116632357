@import "./assets/styles/quill.styles.scss";

/* You can add global styles to this file, and also import other style files */
body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: "open_sansregular", "Helvetica Neue", sans-serif;
  overflow: hidden;
}

ul,
ul li,
label,
p {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

p {
  white-space: pre-line;
  // line-height: 1;
}

textarea:focus,
input:focus,
button:focus,
video:focus,
audio:focus,
div:focus {
  outline: none;
}

video {
  width: 100%;
  height: auto;
}

audio {
  width: 100%;
  height: 54px;
}

strong {
  font-weight: bold;
}

hr {
  margin: 0px;
  border-top: 2px solid;
}

/* Cursor CSS Starts */

.overflow-y {
  overflow-y: scroll;
}

.overflow-x {
  overflow-y: scroll;
}

.scroll::-webkit-scrollbar-track {
  border-radius: 6px !important;
  margin: 8px 0px;
}

.scroll::-webkit-scrollbar {
  border-radius: 6px !important;
  width: 6px;
  height: 6px;
}

.disabled {
  opacity: 1;
  cursor: not-allowed;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 6px !important;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroll-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.shadow {
  box-shadow: 0px 4px 33px #e1e8f2 !important;
}

.relative {
  position: relative;
}

.alias {
  cursor: alias;
}
.all-scroll {
  cursor: all-scroll;
}
.auto {
  cursor: auto;
}
.cell {
  cursor: cell;
}
.context-menu {
  cursor: context-menu;
}
.col-resize {
  cursor: col-resize;
}
.copy {
  cursor: copy;
}
.crosshair {
  cursor: crosshair;
}
.default {
  cursor: default;
}
.e-resize {
  cursor: e-resize;
}
.ew-resize {
  cursor: ew-resize;
}
.grab {
  cursor: grab;
}
.grabbing {
  cursor: grabbing;
}
.help {
  cursor: help;
}
.move {
  cursor: move;
}
.n-resize {
  cursor: n-resize;
}
.ne-resize {
  cursor: ne-resize;
}
.nesw-resize {
  cursor: nesw-resize;
}
.ns-resize {
  cursor: ns-resize;
}
.nw-resize {
  cursor: nw-resize;
}
.nwse-resize {
  cursor: nwse-resize;
}
.no-drop {
  cursor: no-drop;
}
.none {
  cursor: none;
}
.not-allowed {
  cursor: not-allowed;
}
.pointer {
  cursor: pointer;
}
.progress {
  cursor: progress;
}
.row-resize {
  cursor: row-resize;
}
.s-resize {
  cursor: s-resize;
}
.se-resize {
  cursor: se-resize;
}
.sw-resize {
  cursor: sw-resize;
}
.text {
  cursor: text;
}
.w-resize {
  cursor: w-resize;
}
.wait {
  cursor: wait;
}
.zoom-in {
  cursor: zoom-in;
}
.zoom-out {
  cursor: zoom-out;
}

/* Cursor CSS End */

.markdown-clipboard-button {
  border: none;
  border-radius: 4px;
  font-family: "PFCentroSansPro", Sans-serif;
  font-size: 14px;
  padding: 4px 16px;
}

.ui-border {
  border: 1px solid lightgray;
  border-radius: 4px;
}

.custom-dialog-container .mat-dialog-container {
  border-radius: 16px;
  padding: 0;
}

.transparent-dialog-container .mat-dialog-container {
  background-color: transparent;
  box-shadow: none;
}

.white-backdrop {
  background-color: rgba(255, 255, 255, 0.33);
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
}

.black-backdrop {
  background-color: rgba(71, 88, 111, 0.7);
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

/* Page Body Styles */
.page-body {
  padding: 40px 80px 60px 80px;
  height: calc(100vh - 100px);
  overflow-y: scroll;
  overflow-x: clip;
}

.page-body-cx-survey {
  padding: 0px 80px 60px 80px;
  height: calc(100vh - 100px);
  overflow-y: scroll;
  overflow-x: clip;
}

.main-body {
  padding: 40px 80px 60px 80px;
  height: calc(100vh - 60px);
  overflow-y: scroll;
}

@media (max-width: 1600px) {
  .page-body {
    padding: 24px 24px 60px 24px;
    height: calc(100vh - 108px);
  }
  .page-body-cx-survey {
    padding: 0px 24px 60px 24px;
    height: calc(100vh - 108px);
  }
  .main-body {
    padding: 24px 24px 60px 24px;
    height: calc(100vh - 60px);
    overflow-y: scroll;
  }
  .icon-backdrop {
    height: 36px;
    width: 36px;
    border-radius: 18px;
  }
}

@media (max-width: 1200px) {
  .page-body,
  .page-body-cx-survey .main-body {
    padding: 24px 24px 120px 24px;
  }
}

@media (max-width: 500px) {
  .page-body,
  .page-body-cx-survey,
  .main-body {
    padding: 12px 12px 100px 12px;
  }
  .custom-dialog-container .mat-dialog-container {
    width: 100vw;
  }
}
