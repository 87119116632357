/* Flex Box classes Starts */

.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}
.text-right {
  text-align: right;
}

.grid_2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.grid_1 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-around {
  justify-content: space-around;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.justify-evenly {
  justify-content: space-evenly;
}

.flex-end {
  align-items: flex-end;
}

.flex-start {
  align-items: flex-start;
}

.flex-center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}
/* Flex box classes Ends */
