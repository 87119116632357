.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}
.w-21 {
  width: 21%;
}
.w-22 {
  width: 22%;
}
.w-23 {
  width: 23%;
}
.w-24 {
  width: 24%;
}
.w-25 {
  width: 25%;
}
.w-26 {
  width: 26%;
}
.w-27 {
  width: 27%;
}
.w-28 {
  width: 28%;
}
.w-30 {
  width: 30%;
}
.w-35 {
  width: 35%;
}
.w-40 {
  width: 40%;
}
.w-45 {
  width: 45%;
}
.w-50 {
  width: 50%;
}
.w-55 {
  width: 55%;
}
.w-60 {
  width: 60%;
}
.w-65 {
  width: 65%;
}
.w-70 {
  width: 70%;
}
.w-75 {
  width: 75%;
}

.w-76 {
  width: 76%;
}

.w-77 {
  width: 77%;
}
.w-78 {
  width: 78%;
}

.w-79 {
  width: 79%;
}

.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.w-100 {
  width: 100%;
}

/* Application Spacing Start*/
.overflow_spacing {
  margin: 16px 0px 40px 0px;
}

.overflow_spacing_i {
  margin-top: 16px;
}

.h-100 {
  height: 100%;
}

.p-0 {
  padding: 0px;
}

.p-24 {
  padding: 24px;
}

.p-20 {
  padding: 20px;
}

.p-16 {
  padding: 16px;
}
.p-12 {
  padding: 12px;
}
.p-8 {
  padding: 8px;
}
.pl-0 {
  padding-left: 0px;
}
.pl-6 {
  padding-left: 6px;
}
.pl-64 {
  padding-left: 64px;
}
.pl-48 {
  padding-left: 48px;
}
.pl-32 {
  padding-left: 32px;
}
.pl-24 {
  padding-left: 24px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-16 {
  padding-left: 16px;
}
.pl-12 {
  padding-left: 12px;
}
.pl-8 {
  padding-left: 8px;
}
.pl-4 {
  padding-left: 4px !important;
}
.pr-0 {
  padding-right: 0px;
}
.pr-24 {
  padding-right: 24px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-16 {
  padding-right: 16px;
}
.pr-12 {
  padding-right: 12px;
}
.pr-8 {
  padding-right: 8px;
}
.pr-4 {
  padding-right: 4px !important;
}
.pt-0 {
  padding-top: 0px;
}
.pt-24 {
  padding-top: 24px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-16 {
  padding-top: 16px;
}
.pt-12 {
  padding-top: 12px;
}
.pt-14 {
  padding-top: 14px;
}
.pt-8 {
  padding-top: 8px;
}
.pt-4 {
  padding-top: 4px;
}
.pb-0 {
  padding-bottom: 0px;
}
.pb-24 {
  padding-bottom: 24px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pb-12 {
  padding-bottom: 12px;
}
.pb-8 {
  padding-bottom: 8px;
}
.pb-4 {
  padding-bottom: 4px !important;
}
.ph-24 {
  padding: 0 24px;
}
.ph-16 {
  padding: 0 16px;
}
.ph-12 {
  padding: 0 12px;
}
.ph-8 {
  padding: 0 8px;
}
.pv-24 {
  padding: 24px 0;
}
.pv-16 {
  padding: 16px 0;
}
.pv-12 {
  padding: 12px 0;
}
.pv-8 {
  padding: 8px 0;
}

.m-0 {
  margin: 0px;
}

.m-24 {
  margin: 24px;
}
.m-16 {
  margin: 16px;
}
.m-12 {
  margin: 12px;
}
.m-8 {
  margin: 8px;
}
.ml-24 {
  margin-left: 24px;
}
.ml-16 {
  margin-left: 16px;
}
.ml-12 {
  margin-left: 12px;
}
.ml-8 {
  margin-left: 8px;
}
.mr-24 {
  margin-right: 24px;
}
.mr-16 {
  margin-right: 16px;
}
.mr-12 {
  margin-right: 12px;
}
.mr-8 {
  margin-right: 8px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-8 {
  margin-top: 8px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mh-24 {
  margin: 0 24px;
}
.mh-16 {
  margin: 0 16px;
}
.mh-12 {
  margin: 0 12px;
}
.mh-8 {
  margin: 0 8px;
}
.mv-24 {
  margin: 24px 0;
}
.mv-16 {
  margin: 16px 0;
}
.mv-12 {
  margin: 12px 0;
}
.mv-8 {
  margin: 8px 0;
}

.line-break {
  line-break: anywhere;
}

@media (max-width: 1600px) {
  .p-24 {
    padding: 20px;
  }
  .p-16 {
    padding: 14px;
  }
  .p-12 {
    padding: 10px;
  }
  .p-8 {
    padding: 8px;
  }
  .pl-24 {
    padding-left: 20px;
  }
  .pl-20 {
    padding-left: 16px;
  }
  .pl-16 {
    padding-left: 14px;
  }
  .pl-12 {
    padding-left: 10px;
  }
  .pl-8 {
    padding-left: 8px;
  }
  .pr-24 {
    padding-right: 20px;
  }
  .pr-20 {
    padding-right: 16px;
  }
  .pr-16 {
    padding-right: 14px;
  }
  .pr-12 {
    padding-right: 10px;
  }
  .pr-8 {
    padding-right: 8px;
  }
  .pt-24 {
    padding-top: 20px;
  }
  .pt-20 {
    padding-top: 16px;
  }
  .pt-16 {
    padding-top: 14px;
  }
  .pt-12 {
    padding-top: 10px;
  }
  .pt-8 {
    padding-top: 8px;
  }
  .pb-24 {
    padding-bottom: 20px;
  }
  .pb-20 {
    padding-bottom: 16px;
  }
  .pb-16 {
    padding-bottom: 14px;
  }
  .pb-12 {
    padding-bottom: 10px;
  }
  .pb-8 {
    padding-bottom: 8px;
  }
  .ph-24 {
    padding: 0 20px;
  }
  .ph-16 {
    padding: 0 14px;
  }
  .ph-12 {
    padding: 0 10px;
  }
  .ph-8 {
    padding: 0 8px;
  }
  .pv-24 {
    padding: 20px 0;
  }
  .pv-16 {
    padding: 14px 0;
  }
  .pv-12 {
    padding: 10px 0;
  }
  .pv-8 {
    padding: 8px 0;
  }

  .m-24 {
    margin: 20px;
  }
  .m-16 {
    margin: 14px;
  }
  .m-12 {
    margin: 10px;
  }
  .m-8 {
    margin: 8px;
  }
  .ml-24 {
    margin-left: 20px;
  }
  .ml-16 {
    margin-left: 14px;
  }
  .ml-12 {
    margin-left: 10px;
  }
  .ml-8 {
    margin-left: 8px;
  }
  .mr-24 {
    margin-right: 20px;
  }
  .mr-16 {
    margin-right: 14px;
  }
  .mr-12 {
    margin-right: 10px;
  }
  .mr-8 {
    margin-right: 8px;
  }
  .mt-24 {
    margin-top: 20px;
  }
  .mt-16 {
    margin-top: 14px;
  }
  .mt-12 {
    margin-top: 10px;
  }
  .mt-8 {
    margin-top: 8px;
  }
  .mb-24 {
    margin-bottom: 20px;
  }
  .mb-16 {
    margin-bottom: 14px;
  }
  .mb-12 {
    margin-bottom: 10px;
  }
  .mb-8 {
    margin-bottom: 8px;
  }
  .mh-24 {
    margin: 0 20px;
  }
  .mh-16 {
    margin: 0 14px;
  }
  .mh-12 {
    margin: 0 10px;
  }
  .mh-8 {
    margin: 0 8px;
  }
  .mv-24 {
    margin: 20px 0;
  }
  .mv-16 {
    margin: 14px 0;
  }
  .mv-12 {
    margin: 10px 0;
  }
  .mv-8 {
    margin: 8px 0;
  }
  /* Mobile Spacing End */
}

@media (max-width: 500px) {
  .p-24 {
    padding: 16px;
  }
  .p-16 {
    padding: 12px;
  }
  .p-12 {
    padding: 8px;
  }
  .p-8 {
    padding: 4px;
  }
  .pl-24 {
    padding-left: 16px;
  }
  .pl-20 {
    padding-left: 14px;
  }
  .pl-16 {
    padding-left: 12px;
  }
  .pl-12 {
    padding-left: 8px;
  }
  .pl-8 {
    padding-left: 4px;
  }
  .pr-24 {
    padding-right: 16px;
  }
  .pr-20 {
    padding-right: 14px;
  }
  .pr-16 {
    padding-right: 12px;
  }
  .pr-12 {
    padding-right: 8px;
  }
  .pr-8 {
    padding-right: 4px;
  }
  .pt-24 {
    padding-top: 16px;
  }
  .pt-20 {
    padding-top: 14px;
  }
  .pt-16 {
    padding-top: 12px;
  }
  .pt-12 {
    padding-top: 8px;
  }
  .pt-8 {
    padding-top: 4px;
  }
  .pb-24 {
    padding-bottom: 16px;
  }
  .pb-20 {
    padding-bottom: 14px;
  }
  .pb-16 {
    padding-bottom: 12px;
  }
  .pb-12 {
    padding-bottom: 8px;
  }
  .pb-8 {
    padding-bottom: 4px;
  }
  .ph-24 {
    padding: 0 16px;
  }
  .ph-16 {
    padding: 0 12px;
  }
  .ph-12 {
    padding: 0 8px;
  }
  .ph-8 {
    padding: 0 4px;
  }
  .pv-24 {
    padding: 16px 0;
  }
  .pv-16 {
    padding: 12px 0;
  }
  .pv-12 {
    padding: 8px 0;
  }
  .pv-8 {
    padding: 4px 0;
  }

  .m-24 {
    margin: 16px;
  }
  .m-16 {
    margin: 12px;
  }
  .m-12 {
    margin: 8px;
  }
  .m-8 {
    margin: 4px;
  }
  .ml-24 {
    margin-left: 16px;
  }
  .ml-16 {
    margin-left: 12px;
  }
  .ml-12 {
    margin-left: 8px;
  }
  .ml-8 {
    margin-left: 4px;
  }
  .mr-24 {
    margin-right: 16px;
  }
  .mr-16 {
    margin-right: 12px;
  }
  .mr-12 {
    margin-right: 8px;
  }
  .mr-8 {
    margin-right: 4px;
  }
  .mt-24 {
    margin-top: 16px;
  }
  .mt-16 {
    margin-top: 12px;
  }
  .mt-12 {
    margin-top: 8px;
  }
  .mt-8 {
    margin-top: 4px;
  }
  .mb-24 {
    margin-bottom: 16px;
  }
  .mb-16 {
    margin-bottom: 12px;
  }
  .mb-12 {
    margin-bottom: 8px;
  }
  .mb-8 {
    margin-bottom: 4px;
  }
  .mh-24 {
    margin: 0 16px;
  }
  .mh-16 {
    margin: 0 12px;
  }
  .mh-12 {
    margin: 0 8px;
  }
  .mh-8 {
    margin: 0 4px;
  }
  .mv-24 {
    margin: 16px 0;
  }
  .mv-16 {
    margin: 12px 0;
  }
  .mv-12 {
    margin: 8px 0;
  }
  .mv-8 {
    margin: 4px 0;
  }
  /* Mobile Spacing End */
}

/* Tab spacing Start */
@media (max-width: 1199px) {
  .overflow_spacing {
    margin: 0px 0px 40px 0px;
  }
  .overflow_spacing_i {
    margin-top: 0px;
  }
}
/* Tab spcaing End */
