@font-face {
  font-family: "PFCentroSansProLight";
  src: url("../../app/library/font/PFCentroSansPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "PFCentroSansProBold";
  src: url("../../app/library/font/PFCentroSansPro-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "PFCentroSansProRegular";
  src: url("../../app/library/font/PFCentroSansPro-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "PFCentroSansProMedium";
  src: url("../../app/library/font/PFCentroSansPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "PFCentroSansProItalic";
  src: url("../../app/library/font/PFCentroSansPro-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

.font-opacity {
  opacity: 0.8;
}
.font-break {
  word-wrap: break-word;
}
.font-no-break {
  white-space: nowrap;
}
.font-clip {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.font-48 {
  font-family: "PFCentroSansProRegular", Sans-serif;
  font-size: 48px;
}
.font-48-b {
  font-family: "PFCentroSansProBold", Sans-serif;
  font-size: 48px;
}
.font-48-sb {
  font-family: "PFCentroSansProMedium", Sans-serif;
  font-size: 48px;
}
.font-48-l {
  font-family: "PFCentroSansProLight", Sans-serif;
  font-size: 48px;
}
.font-48-i {
  font-family: "PFCentroSansProItalic", Sans-serif;
  font-size: 48px;
}

.font-36 {
  font-family: "PFCentroSansProRegular", Sans-serif;
  font-size: 36px;
}
.font-36-b {
  font-family: "PFCentroSansProBold", Sans-serif;
  font-size: 36px;
}
.font-36-sb {
  font-family: "PFCentroSansProMedium", Sans-serif;
  font-size: 36px;
}
.font-36-l {
  font-family: "PFCentroSansProLight", Sans-serif;
  font-size: 36px;
}
.font-36-i {
  font-family: "PFCentroSansProItalic", Sans-serif;
  font-size: 36px;
}

.font-28 {
  font-family: "PFCentroSansProRegular", Sans-serif;
  font-size: 28px;
}
.font-28-b {
  font-family: "PFCentroSansProBold", Sans-serif;
  font-size: 28px;
}
.font-28-sb {
  font-family: "PFCentroSansProMedium", Sans-serif;
  font-size: 28px;
}
.font-28-l {
  font-family: "PFCentroSansProLight", Sans-serif;
  font-size: 28px;
}
.font-28-i {
  font-family: "PFCentroSansProItalic", Sans-serif;
  font-size: 28px;
}

.font-24 {
  font-family: "PFCentroSansProRegular", Sans-serif;
  font-size: 24px;
}
.font-24-b {
  font-family: "PFCentroSansProBold", Sans-serif;
  font-size: 24px;
}
.font-24-sb {
  font-family: "PFCentroSansProMedium", Sans-serif;
  font-size: 24px;
}
.font-24-l {
  font-family: "PFCentroSansProLight", Sans-serif;
  font-size: 24px;
}
.font-24-i {
  font-family: "PFCentroSansProItalic", Sans-serif;
  font-size: 24px;
}

.font-20 {
  font-family: "PFCentroSansProRegular", Sans-serif;
  font-size: 20px;
}
.font-20-b {
  font-family: "PFCentroSansProBold", Sans-serif;
  font-size: 20px;
}
.font-20-sb {
  font-family: "PFCentroSansProMedium", Sans-serif;
  font-size: 20px;
}
.font-20-l {
  font-family: "PFCentroSansProLight", Sans-serif;
  font-size: 20px;
}
.font-20-i {
  font-family: "PFCentroSansProItalic", Sans-serif;
  font-size: 20px;
}

.font-18 {
  font-family: "PFCentroSansProRegular", Sans-serif;
  font-size: 18px;
}
.font-18-b {
  font-family: "PFCentroSansProBold", Sans-serif;
  font-size: 18px;
}
.font-18-sb {
  font-family: "PFCentroSansProMedium", Sans-serif;
  font-size: 18px;
}
.font-18-l {
  font-family: "PFCentroSansProLight", Sans-serif;
  font-size: 18px;
}
.font-18-i {
  font-family: "PFCentroSansProItalic", Sans-serif;
  font-size: 18px;
}

.font-16 {
  font-family: "PFCentroSansProRegular", Sans-serif;
  font-size: 16px;
}
.font-16-b {
  font-family: "PFCentroSansProBold", Sans-serif;
  font-size: 16px;
}
.font-16-sb {
  font-family: "PFCentroSansProMedium", Sans-serif;
  font-size: 16px;
}
.font-16-l {
  font-family: "PFCentroSansProLight", Sans-serif;
  font-size: 16px;
}
.font-16-i {
  font-family: "PFCentroSansProItalic", Sans-serif;
  font-size: 16px;
  font-style: italic;
}

.font-14 {
  font-family: "PFCentroSansProRegular", Sans-serif;
  font-size: 14px;
}
.font-14-b {
  font-family: "PFCentroSansProBold", Sans-serif;
  font-size: 14px;
}
.font-14-sb {
  font-family: "PFCentroSansProMedium", Sans-serif;
  font-size: 14px;
}
.font-14-l {
  font-family: "PFCentroSansProLight", Sans-serif;
  font-size: 14px;
}
.font-14-i {
  font-family: "PFCentroSansProItalic", Sans-serif;
  font-size: 14px;
}

.shimmer-14 {
  animation: shimmer 6s infinite linear;
  height: 14px;
}

.shimmer-16 {
  animation: shimmer 6s infinite linear;
  height: 16px;
}

.shimmer-18 {
  animation: shimmer 6s infinite linear;
  height: 18px;
}

.shimmer-24 {
  animation: shimmer 6s infinite linear;
  height: 24px;
}

.shimmer-28 {
  animation: shimmer 6s infinite linear;
  height: 28px;
}

.shimmer-btn {
  animation: shimmer 6s infinite linear;
  height: 44px;
  border-radius: 24px;
}

@media (max-width: 1600px) {
  .font-48 {
    font-family: "PFCentroSansProRegular", Sans-serif;
    font-size: 42px;
  }
  .font-48-b {
    font-family: "PFCentroSansProBold", Sans-serif;
    font-size: 42px;
  }
  .font-48-sb {
    font-family: "PFCentroSansProMedium", Sans-serif;
    font-size: 42px;
  }
  .font-48-l {
    font-family: "PFCentroSansProLight", Sans-serif;
    font-size: 42px;
  }
  .font-48-i {
    font-family: "PFCentroSansProItalic", Sans-serif;
    font-size: 42px;
  }
  .font-36 {
    font-family: "PFCentroSansProRegular", Sans-serif;
    font-size: 34px;
  }
  .font-36-b {
    font-family: "PFCentroSansProBold", Sans-serif;
    font-size: 34px;
  }
  .font-36-sb {
    font-family: "PFCentroSansProMedium", Sans-serif;
    font-size: 34px;
  }
  .font-36-l {
    font-family: "PFCentroSansProLight", Sans-serif;
    font-size: 34px;
  }
  .font-36-i {
    font-family: "PFCentroSansProItalic", Sans-serif;
    font-size: 34px;
  }
  .font-28 {
    font-family: "PFCentroSansProRegular", Sans-serif;
    font-size: 22px;
  }
  .font-28-b {
    font-family: "PFCentroSansProBold", Sans-serif;
    font-size: 22px;
  }
  .font-28-sb {
    font-family: "PFCentroSansProMedium", Sans-serif;
    font-size: 22px;
  }
  .font-28-l {
    font-family: "PFCentroSansProLight", Sans-serif;
    font-size: 22px;
  }
  .font-28-i {
    font-family: "PFCentroSansProItalic", Sans-serif;
    font-size: 22px;
  }

  .font-24 {
    font-family: "PFCentroSansProRegular", Sans-serif;
    font-size: 20px;
  }
  .font-24-b {
    font-family: "PFCentroSansProBold", Sans-serif;
    font-size: 20px;
  }
  .font-24-sb {
    font-family: "PFCentroSansProMedium", Sans-serif;
    font-size: 20px;
  }
  .font-24-l {
    font-family: "PFCentroSansProLight", Sans-serif;
    font-size: 20px;
  }
  .font-24-i {
    font-family: "PFCentroSansProItalic", Sans-serif;
    font-size: 20px;
  }

  .font-20 {
    font-family: "PFCentroSansProRegular", Sans-serif;
    font-size: 18px;
  }
  .font-20-b {
    font-family: "PFCentroSansProBold", Sans-serif;
    font-size: 18px;
  }
  .font-20-sb {
    font-family: "PFCentroSansProMedium", Sans-serif;
    font-size: 18px;
  }
  .font-20-l {
    font-family: "PFCentroSansProLight", Sans-serif;
    font-size: 18px;
  }
  .font-20-i {
    font-family: "PFCentroSansProItalic", Sans-serif;
    font-size: 18px;
  }

  .font-18 {
    font-family: "PFCentroSansProRegular", Sans-serif;
    font-size: 16px;
  }
  .font-18-b {
    font-family: "PFCentroSansProBold", Sans-serif;
    font-size: 16px;
  }
  .font-18-sb {
    font-family: "PFCentroSansProMedium", Sans-serif;
    font-size: 16px;
  }
  .font-18-l {
    font-family: "PFCentroSansProLight", Sans-serif;
    font-size: 16px;
  }
  .font-18-i {
    font-family: "PFCentroSansProItalic", Sans-serif;
    font-size: 16px;
  }

  .font-16 {
    font-family: "PFCentroSansProRegular", Sans-serif;
    font-size: 14px;
  }
  .font-16-b {
    font-family: "PFCentroSansProBold", Sans-serif;
    font-size: 14px;
  }
  .font-16-sb {
    font-family: "PFCentroSansProMedium", Sans-serif;
    font-size: 14px;
  }
  .font-16-l {
    font-family: "PFCentroSansProLight", Sans-serif;
    font-size: 14px;
  }
  .font-16-i {
    font-family: "PFCentroSansProItalic", Sans-serif;
    font-size: 14px;
  }

  .font-14 {
    font-family: "PFCentroSansProRegular", Sans-serif;
    font-size: 12px;
  }
  .font-14-b {
    font-family: "PFCentroSansProBold", Sans-serif;
    font-size: 12px;
  }
  .font-14-sb {
    font-family: "PFCentroSansProMedium", Sans-serif;
    font-size: 12px;
  }
  .font-14-l {
    font-family: "PFCentroSansProLight", Sans-serif;
    font-size: 12px;
  }
  .font-14-i {
    font-family: "PFCentroSansProItalic", Sans-serif;
    font-size: 12px;
  }

  .shimmer-14 {
    animation: shimmer 6s infinite linear;
    height: 12px;
  }

  .shimmer-16 {
    animation: shimmer 6s infinite linear;
    height: 14px;
  }

  .shimmer-18 {
    animation: shimmer 6s infinite linear;
    height: 16px;
  }

  .shimmer-24 {
    animation: shimmer 6s infinite linear;
    height: 20px;
  }

  .shimmer-28 {
    animation: shimmer 6s infinite linear;
    height: 24px;
  }

  .shimmer-btn {
    animation: shimmer 6s infinite linear;
    height: 38px;
    border-radius: 20px;
  }
}

@media (max-width: 500px) {
  .font-48 {
    font-family: "PFCentroSansProRegular", Sans-serif;
    font-size: 36px;
  }
  .font-48-b {
    font-family: "PFCentroSansProBold", Sans-serif;
    font-size: 36px;
  }
  .font-48-sb {
    font-family: "PFCentroSansProMedium", Sans-serif;
    font-size: 36px;
  }
  .font-48-l {
    font-family: "PFCentroSansProLight", Sans-serif;
    font-size: 36px;
  }
  .font-48-i {
    font-family: "PFCentroSansProItalic", Sans-serif;
    font-size: 36px;
  }
  .font-36 {
    font-family: "PFCentroSansProRegular", Sans-serif;
    font-size: 28px;
  }
  .font-36-b {
    font-family: "PFCentroSansProBold", Sans-serif;
    font-size: 28px;
  }
  .font-36-sb {
    font-family: "PFCentroSansProMedium", Sans-serif;
    font-size: 28px;
  }
  .font-36-l {
    font-family: "PFCentroSansProLight", Sans-serif;
    font-size: 28px;
  }
  .font-36-i {
    font-family: "PFCentroSansProItalic", Sans-serif;
    font-size: 28px;
  }
  .font-28 {
    font-family: "PFCentroSansProRegular", Sans-serif;
    font-size: 22px;
  }
  .font-28-b {
    font-family: "PFCentroSansProBold", Sans-serif;
    font-size: 22px;
  }
  .font-28-sb {
    font-family: "PFCentroSansProMedium", Sans-serif;
    font-size: 22px;
  }
  .font-28-l {
    font-family: "PFCentroSansProLight", Sans-serif;
    font-size: 22px;
  }
  .font-28-i {
    font-family: "PFCentroSansProItalic", Sans-serif;
    font-size: 22px;
  }

  .font-24 {
    font-family: "PFCentroSansProRegular", Sans-serif;
    font-size: 20px;
  }
  .font-24-b {
    font-family: "PFCentroSansProBold", Sans-serif;
    font-size: 20px;
  }
  .font-24-sb {
    font-family: "PFCentroSansProMedium", Sans-serif;
    font-size: 20px;
  }
  .font-24-l {
    font-family: "PFCentroSansProLight", Sans-serif;
    font-size: 20px;
  }
  .font-24-i {
    font-family: "PFCentroSansProItalic", Sans-serif;
    font-size: 20px;
  }

  .font-20 {
    font-family: "PFCentroSansProRegular", Sans-serif;
    font-size: 18px;
  }
  .font-20-b {
    font-family: "PFCentroSansProBold", Sans-serif;
    font-size: 18px;
  }
  .font-20-sb {
    font-family: "PFCentroSansProMedium", Sans-serif;
    font-size: 18px;
  }
  .font-20-l {
    font-family: "PFCentroSansProLight", Sans-serif;
    font-size: 18px;
  }
  .font-20-i {
    font-family: "PFCentroSansProItalic", Sans-serif;
    font-size: 18px;
  }

  .font-18 {
    font-family: "PFCentroSansProRegular", Sans-serif;
    font-size: 16px;
  }
  .font-18-b {
    font-family: "PFCentroSansProBold", Sans-serif;
    font-size: 16px;
  }
  .font-18-sb {
    font-family: "PFCentroSansProMedium", Sans-serif;
    font-size: 16px;
  }
  .font-18-l {
    font-family: "PFCentroSansProLight", Sans-serif;
    font-size: 16px;
  }
  .font-18-i {
    font-family: "PFCentroSansProItalic", Sans-serif;
    font-size: 16px;
  }

  .font-16 {
    font-family: "PFCentroSansProRegular", Sans-serif;
    font-size: 14px;
  }
  .font-16-b {
    font-family: "PFCentroSansProBold", Sans-serif;
    font-size: 14px;
  }
  .font-16-sb {
    font-family: "PFCentroSansProMedium", Sans-serif;
    font-size: 14px;
  }
  .font-16-l {
    font-family: "PFCentroSansProLight", Sans-serif;
    font-size: 14px;
  }
  .font-16-i {
    font-family: "PFCentroSansProItalic", Sans-serif;
    font-size: 14px;
  }

  .font-14 {
    font-family: "PFCentroSansProRegular", Sans-serif;
    font-size: 12px;
  }
  .font-14-b {
    font-family: "PFCentroSansProBold", Sans-serif;
    font-size: 12px;
  }
  .font-14-sb {
    font-family: "PFCentroSansProMedium", Sans-serif;
    font-size: 12px;
  }
  .font-14-l {
    font-family: "PFCentroSansProLight", Sans-serif;
    font-size: 12px;
  }
  .font-14-i {
    font-family: "PFCentroSansProItalic", Sans-serif;
    font-size: 12px;
  }
}
