/* ICONS */
.icon-56 {
  background-repeat: no-repeat;
  height: 56px;
  width: 56px;
  background-size: 56px;
  min-width: 56px;
}

.icon-40 {
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  min-width: 40px;
  background-size: 40px;
}

.icon-44 {
  background-repeat: no-repeat;
  height: 44px;
  width: 44px;
  background-size: 44px;
}

.icon-48 {
  background-repeat: no-repeat;
  height: 48px;
  width: 48px;
  background-size: 48px;
}

.icon-32 {
  background-repeat: no-repeat;
  height: 32px;
  width: 32px;
  min-width: 32px;
  background-size: 32px;
}

.icon-36 {
  background-repeat: no-repeat;
  height: 36px;
  width: 36px;
  background-size: 36px;
}

.icon-20 {
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  min-width: 20px;
  background-size: 20px;
}

.icon-24 {
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  min-width: 24px;
  background-size: 24px;
}

.icon-16 {
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  min-width: 16px;
  background-size: 16px;
}

.icon-12 {
  background-repeat: no-repeat;
  height: 12px;
  width: 12px;
  min-width: 12px;
  background-size: 12px;
}

@media (max-width: 500px) {
  /* Mobile Icon Start*/
  .icon-56 {
    height: 40px !important;
    width: 40px !important;
    background-size: 40px !important;
    min-width: 40px !important;
  }
  .icon-40 {
    height: 32px !important;
    width: 32px !important;
    min-width: 32px !important;
    background-size: 32px !important;
  }
  .icon-44 {
    height: 36px !important;
    width: 36px !important;
    background-size: 36px !important;
  }
  .icon-48 {
    height: 40px !important;
    width: 40px !important;
    background-size: 40px !important;
  }
  .icon-32 {
    height: 24px !important;
    width: 24px !important;
    min-width: 24px !important;
    background-size: 24px !important;
  }
  .icon-36 {
    height: 28px !important;
    width: 28px !important;
    background-size: 28px !important;
  }
  .icon-20 {
    height: 16px !important;
    width: 16px !important;
    min-width: 16px !important;
    background-size: 16px !important;
  }
  .icon-24 {
    height: 20px !important;
    width: 20px !important;
    min-width: 20px !important;
    background-size: 20px !important;
  }
  .icon-16 {
    height: 12px !important;
    width: 12px !important;
    min-width: 12px !important;
    background-size: 12px !important;
  }
  .icon-12 {
    height: 8px !important;
    width: 8px !important;
    min-width: 8px !important;
    background-size: 8px !important;
  }
}
